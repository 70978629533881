// For web
import web_ar from "./privacy/ar.json";
import web_ca from "./privacy/ca.json";
import web_cs from "./privacy/cs.json";
import web_da from "./privacy/da.json";
import web_de from "./privacy/de.json";
import web_el from "./privacy/el.json";
import web_en from "./privacy/en.json";
import web_es from "./privacy/es.json";
import web_fi from "./privacy/fi.json";
import web_fr from "./privacy/fr.json";
import web_he from "./privacy/he.json";
import web_hi from "./privacy/hi.json";
import web_hr from "./privacy/hr.json";
import web_hu from "./privacy/hu.json";
import web_id from "./privacy/id.json";
import web_it from "./privacy/it.json";
import web_ja from "./privacy/ja.json";
import web_ko from "./privacy/ko.json";
import web_ms from "./privacy/ms.json";
import web_nl from "./privacy/nl.json";
import web_no from "./privacy/no.json";
import web_pl from "./privacy/pl.json";
import web_pt from "./privacy/pt.json";
import web_ro from "./privacy/ro.json";
import web_ru from "./privacy/ru.json";
import web_sk from "./privacy/sk.json";
import web_sv from "./privacy/sv.json";
import web_th from "./privacy/th.json";
import web_tr from "./privacy/tr.json";
import web_uk from "./privacy/uk.json";
import web_vi from "./privacy/vi.json";



// App privacy
import app_ar from "./appPrivacy/ar.json";
import app_ca from "./appPrivacy/ca.json";
import app_cs from "./appPrivacy/cs.json";
import app_da from "./appPrivacy/da.json";
import app_de from "./appPrivacy/de.json";
import app_el from "./appPrivacy/el.json";
import app_en from "./appPrivacy/en.json";
import app_es from "./appPrivacy/es.json";
import app_fi from "./appPrivacy/fi.json";
import app_fr from "./appPrivacy/fr.json";
import app_he from "./appPrivacy/he.json";
import app_hi from "./appPrivacy/hi.json";
import app_hr from "./appPrivacy/hr.json";
import app_hu from "./appPrivacy/hu.json";
import app_id from "./appPrivacy/id.json";
import app_it from "./appPrivacy/it.json";
import app_ja from "./appPrivacy/ja.json";
import app_ko from "./appPrivacy/ko.json";
import app_ms from "./appPrivacy/ms.json";
import app_nl from "./appPrivacy/nl.json";
import app_no from "./appPrivacy/no.json";
import app_pl from "./appPrivacy/pl.json";
import app_pt from "./appPrivacy/pt.json";
import app_ro from "./appPrivacy/ro.json";
import app_ru from "./appPrivacy/ru.json";
import app_sk from "./appPrivacy/sk.json";
import app_sv from "./appPrivacy/sv.json";
import app_th from "./appPrivacy/th.json";
import app_tr from "./appPrivacy/tr.json";
import app_uk from "./appPrivacy/uk.json";
import app_vi from "./appPrivacy/vi.json";

export const languages = {
  home: {
    en: {
      languagesText: "Arabic, Chinese (Simplified), Chinese (Traditional), Czech, Danish, Dutch, English, Finnish, French, German, Hebrew, Hindi, Hungarian, Italian, Japanese, Korean, Norwegian, Polish, Portuguese, Romanian, Russian, Slovak, Spanish, Swedish, Turkish, and Ukrainian.",
      downloadAppText: "Download on the Apple App Store for iPhone",
      downloadAppHeading: "DOWNLOAD THE APP FOR FREE",
      downloadAppSubHeading: "Designed for Apple iOS",
      pagesLegalText: "A digital signature can carry the same legal value as a handwritten signature for most documents. However, the legal validity depends on the regulations of each country and specific use cases. It is the user’s responsibility to verify the acceptance of digital signatures in their jurisdiction. easyConsent® does not accept responsibility for this.",
      align: "center",
      label: "English",
      headingText1: "Consent",
      headingText2: "Anywhere",
      headingText3: "Anytime",
      subHeading:
        "The Most Secure and Intuitive Consent App Available Today.",
      subsubHeading:
        "easyConsent® is a family app that simplifies consent management. It ensures privacy, respects personal choices, and offers easy control with legally binding agreements.",
      commitmentHeading: "One App - 26 Languages",
      commitmentSubHeading: "Consent in Your Language, Anytime, Anywhere",
        innovationHeading: "Sign your Consent in the App",
        innovationSubHeading: "Use your Finger or Pencil to Sign, Anytime, Anywhere",
        pioneersHeading: "PIONEERS WANTED",
        pioneersSubHeading: "Ready to Engineer the Unseen?",
        pioneersSubSubHeading1: "We seek the bold and the imaginative, those ready to engineer the unseen. Our journey is one of discovery, innovation, and crafting solutions that push the boundaries of what technology can achieve. We're not just building software; we're shaping the digital future. With a foundation built on integrity, excellence, and a commitment to societal betterment, we invite you to join us.",
        pioneersSubSubHeading2: "Together, we master the challenges of today and tomorrow, creating impactful technologies that empower, connect, and inspire a global community. Your expertise and vision are the keys to unlocking new realms of possibility, driving us towards a future where technology amplifies human potential and fosters a more inclusive world.",
      whatsNextHeading: "SEE WHATS NEXT",
      whatsNextSubHeading: "Today's Innovations, Tomorrow's Standards",
      whatsNextSubSubHeading:
        "Explore and discover how we're turning innovative ideas into everyday realities, setting new benchmarks in the app world, and continuously pushing the boundaries of what's possible.",
      contactSectionHeading: "GET IN TOUCH WITH US",
      contactSectionSubHeading: "Let’s Innovate Together",
      ideasText: "Your ideas and investment can shape the future of technology. Whether you're an enthusiastic user, an investor looking to make an impact, or a potential partner, we're excited to hear from you.",
      namePlaceholder: "Your Name",
      emailPlaceholder: "Your Email",
      messagePlaceholder: "Your Message",
      submitButtonText: "WRITE US A MESSAGE",
      privacyText:
        "We respect your privacy. By submitting a message to us, you explicitly agree to our Terms of Use and acknowledge our Privacy Policy & Cookie Policy. Your information will be used to respond to your inquiry and as described in our Privacy Policy. For information on how to withdraw consent or modify your data, please see our Privacy Policy.",
      legalInformationHeadline: "Legal Information",
      legalInformationSubHeadline:
        "Stay Informed About Your Rights and Our Policies",
      termsOfUseText: "Terms of Use",
      privacyPolicyText: "Privacy Policy",
      cookiePolicy: "Cookie Policy",
    },
    es: {
      align: "left",
      label: "Español",
      downloadAppText: "Descárguela en el Apple App Store para iPhone",
      downloadAppHeading: "DESCARGUE LA APLICACIÓN GRATIS",
      downloadAppSubHeading: "Diseñada para Apple iOS",
      languagesText:"Árabe, Chino (Simplificado), Chino (Tradicional), Checo, Danés, Neerlandés, Inglés, Finés, Francés, Alemán, Hebreo, Hindi, Húngaro, Italiano, Japonés, Coreano, Noruego, Polaco, Portugués, Rumano, Ruso, Eslovaco, Español, Sueco, Turco y Ucraniano.",
      readThisSection: "Lee esta sección",
      headingText1: "Consent",
      headingText2: "Anywhere",
      headingText3: "Anytime",
      pagesLegalText: "Una firma digital puede tener el mismo valor legal que una firma manuscrita para la mayoría de los documentos. Sin embargo, la validez legal depende de las regulaciones de cada país y de los casos específicos. Es responsabilidad del usuario verificar la aceptación de firmas digitales en su jurisdicción. easyConsent® no acepta responsabilidad por ello.",
      subHeading:
        "La aplicación de consentimientos más segura e intuitiva disponible hoy en día.",
      subsubHeading:
        "easyConsent® es una aplicación familiar que simplifica la gestión de consentimientos. Garantiza la privacidad, respeta las decisiones personales y ofrece un control fácil con acuerdos legalmente vinculantes.",
      commitmentHeading: "Una aplicación - 26 idiomas",
      commitmentSubHeading: "Use la aplicación en su idioma habitual, en cualquier momento y lugar.",
      commitmentSubSubHeading:
        "Con un propósito claro en mente, desarrollamos aplicaciones innovadoras que mejoran la vida, empoderan a los individuos y crean un futuro mejor para todos. Nuestro trabajo está guiado por principios de excelencia moral e integridad, asegurando honestidad y conducta ética en todas nuestras transacciones comerciales. Este compromiso se extiende a cada interacción, fomentando la confianza de nuestros usuarios y partes interesadas.",
        commitmentSubSubHeading2:"En el núcleo de nuestra misión yace el profundo deseo de tener un impacto positivo tangible, lo que nos motiva a innovar y contribuir de manera significativa a la sociedad. Miramos más allá del beneficio económico, enfocando nuestra creatividad y experiencia en desarrollar soluciones que no solo mejoren la calidad de vida, sino que también proporcionen a los usuarios las herramientas necesarias para prosperar. Con nuestras aplicaciones, aspiramos a abordar desafíos reales y fomentar una comunidad global más conectada y digitalizada.",
        innovationHeading: "FIRME SUS CONSENTIMIENTOS DIRECTAMENTE EN LA APLICACIÓN",
        innovationSubHeading: "Use su dedo o el Apple Pencil para firmar, en cualquier momento y lugar.",
        innovationSubSubHeading1: "Al extender nuestra misión hacia nuevos horizontes, presentamos un servicio dedicado al desarrollo de soluciones de software a medida. Este empeño se basa en nuestro compromiso con la innovación y la calidad, buscando transformar cómo empresas e individuos interactúan con la tecnología. Aprovechamos los últimos avances técnicos para crear software que no solo esté centrado en el usuario, sino que también sea un motor de cambio positivo, reflejando nuestra visión de una sociedad más empoderada.",
        innovationSubSubHeading2: "Cada proyecto es un paso hacia la superación de los desafíos únicos de nuestro tiempo, respaldado por nuestro conocimiento experto y una comprensión profunda del ecosistema digital. A través de un desarrollo meticuloso y un compromiso colaborativo, nos esforzamos por entregar soluciones de software que superen las expectativas, asegurando que nuestras soluciones no solo sean efectivas, sino que también establezcan estándares de excelencia tecnológica y responsabilidad social.",
        pioneersHeading: "PIONEROS BUSCADOS",
        pioneersSubHeading: "¿Listo para moldear lo desconocido?",
        pioneersSubSubHeading1: "Buscamos a los valientes y a los creativos. Nuestro viaje es uno de descubrimiento, innovación y desarrollo de soluciones que amplían los límites de lo que la tecnología puede lograr. Juntos, superamos los desafíos de hoy y mañana, creando tecnologías impactantes que fortalecen e inspiran a una comunidad global.",
        pioneersSubSubHeading2: "Tu experiencia y visión son la clave para desbloquear nuevos ámbitos de posibilidad, impulsándonos hacia un futuro en el que la tecnología amplifica el potencial humano. No solo estamos construyendo software; estamos redefiniendo el futuro digital y te invitamos a unirte a nosotros.",
      whatsNextHeading: "DESCUBRE LO QUE VIENE A CONTINUACIÓN",
      whatsNextSubHeading: "Innovaciones de Hoy, Estándares del Mañana",
      whatsNextSubSubHeading:
        "EasyConsent es una aplicación familiar que simplifica y agiliza la gestión de consentimientos digitales, garantizando que se respeten la privacidad y las elecciones personales, sean fácilmente controlables y legalmente vinculantes.",
      contactSectionHeading: "CONTÁCTANOS",
      contactSectionSubHeading: "Hablemos sobre tus idea",
      ideasText: "Your ideas and investment can shape the future of technology. Whether you're an enthusiastic user, an investor looking to make an impact, or a potential partner, we're excited to hear from you.",
      namePlaceholder: "Tu Nombre",
      emailPlaceholder: "Tu Correo Electrónico",
      messagePlaceholder: "Tu Mensaje",
      submitButtonText: "ESCRÍBENOS UN MENSAJE",
      privacyText:
        "Respetamos tu privacidad. Al enviarnos un mensaje, aceptas explícitamente nuestros Términos de Uso y reconoces nuestra Política de Privacidad y Política de Cookies. Tu información será utilizada para responder a tu consulta tal como se describe en nuestra Política de Privacidad. Para información sobre cómo retirar el consentimiento o modificar tus datos, por favor consulta nuestra Política de Privacidad.",
      legalInformationHeadline: "INFORMACIÓN LEGAL",
      legalInformationSubHeadline:
        "Mantente informado sobre tus derechos y nuestras políticas",
      termsOfUseText: "Términos de uso",
      privacyPolicyText: "Política de privacidad",
      cookiePolicy: "Política de cookies",
    },
    de: {
      align: "left",
      label: "Deutsch",
      downloadAppHeading: "LADEN SIE DIE APP KOSTENLOS HERUNTER",
      downloadAppSubHeading: "Entwickelt für Apple iOS",
      downloadAppText: "Im Apple App Store für iPhone herunterladen",
      languagesText: "Arabisch, Chinesisch (vereinfacht), Chinesisch (traditionell), Tschechisch, Dänisch, Niederländisch, Englisch, Finnisch, Französisch, Deutsch, Hebräisch, Hindi, Ungarisch, Italienisch, Japanisch, Koreanisch, Norwegisch, Polnisch, Portugiesisch, Rumänisch, Russisch, Slowakisch, Spanisch, Schwedisch, Türkisch und Ukrainisch.",
      readThisSection: "Diesen Abschnitt vorlesen",
      headingText1: "Consent",
      headingText2: "Anywhere",
      headingText3: "Anytime",
      pagesLegalText: "Eine digitale Signatur kann für die meisten Dokumente den gleichen rechtlichen Wert wie eine handschriftliche Unterschrift haben. Die rechtliche Gültigkeit hängt jedoch von den Vorschriften des jeweiligen Landes und dem konkreten Anwendungsfall ab. Es liegt in der Verantwortung des Nutzers, die Anerkennung digitaler Signaturen in seiner Rechtsordnung zu prüfen. easyConsent® übernimmt hierfür keine Verantwortung.",
      subHeading:
        "Die sicherste und intuitivste Consent-App, die es gibt.",
      subsubHeading:
        "easyConsent® ist eine Familien-App, die das Management von Einverständniserklärungen vereinfacht. Sie sorgt für Datenschutz, respektiert persönliche Entscheidungen und bietet einfache Kontrolle mit rechtsverbindlichen Vereinbarungen.",
      commitmentHeading: "Eine App - 26 Sprachen",
      commitmentSubHeading: "Nutzen Sie die App in Ihrer gewohnten Sprache – jederzeit und überall.",
      commitmentSubSubHeading:
        "Mit einem klaren Ziel vor Augen entwickeln wir innovative Apps, die das Leben verbessern, den Einzelnen ermächtigen und eine bessere Zukunft für alle gestalten. Unsere Arbeit wird von Prinzipien der moralischen Exzellenz und Integrität geleitet und garantiert Ehrlichkeit und ethisches Verhalten in all unseren Geschäftsbeziehungen. Dieses Versprechen durchdringt jede Interaktion und fördert das Vertrauen unserer Nutzer und Stakeholder.",
        commitmentSubSubHeading2:"Im Kern unserer Mission liegt der tiefe Wunsch, einen spürbaren positiven Einfluss zu erzielen, der uns dazu antreibt, Neues zu erfinden und sinnvoll zur Gesellschaft beizutragen. Wir blicken über den Profit hinaus und konzentrieren unsere Kreativität und Fachkenntnisse darauf, Lösungen zu entwickeln, die nicht nur die Lebensqualität verbessern, sondern auch den Nutzern die notwendigen Werkzeuge in die Hände geben, die sie wirklich brauchen. Mit unseren Apps wollen wir echte Herausforderungen überwinden und eine vernetztere und digitalisierte globale Gemeinschaft fördern.",
        innovationHeading: "UNTERZEICHNEN SIE IHRE EINVERSTÄNDNISERKLÄRUNGEN DIREKT IN DER APP",
        innovationSubHeading: "Ihre individuellen Softwarelösungen",
        innovationSubSubHeading1: "Indem wir unsere Mission auf neue Horizonte ausweiten, bieten wir einen spezialisierten Dienst für die Entwicklung individueller Softwarelösungen an. Dieses Vorhaben basiert auf unserem Engagement für Innovation und Qualität und zielt darauf ab, die Interaktion von Unternehmen und Einzelpersonen mit Technologie zu transformieren. Wir nutzen die neuesten technischen Fortschritte, um Software zu erstellen, die nicht nur nutzerzentriert ist, sondern auch als Motor für positive Veränderung dient und unsere Vision einer gestärkten Gesellschaft widerspiegelt.",
        innovationSubSubHeading2: "Jedes Projekt ist ein Schritt in Richtung Lösung spezifischer Herausforderungen, unterstützt durch unser umfassendes Fachwissen und ein fundiertes Verständnis des digitalen Ökosystems. Mit sorgfältiger Entwicklung und Zusammenarbeit zielen wir darauf ab, Softwarelösungen zu entwickeln, die die Erwartungen übertrifft, indem wir sicherstellen, dass unsere Lösungen nicht nur effektiv, sondern auch Maßstäbe für technologische Exzellenz und Verantwortung setzen.",
        pioneersHeading: "PIONIERE GESUCHT",
        pioneersSubHeading: "Bereit, das Unbekannte zu gestalten?",
        pioneersSubSubHeading1: "Wir suchen die Mutigen und die Kreativen. Unsere Reise ist eine der Entdeckung, Innovation und der Entwicklung von Lösungen, die die Grenzen dessen, was Technologie leisten kann, erweitern. Gemeinsam meistern wir die Herausforderungen von heute und morgen, indem wir einflussreiche Technologien schaffen, die eine weltweite Gemeinschaft stärken und inspirieren.",
        pioneersSubSubHeading2: "Deine Fachkenntnisse und Vision sind der Schlüssel, um neue Möglichkeiten zu erschließen und uns auf eine Zukunft zuzubewegen, in der Technologie das menschliche Potenzial verstärkt. Wir bauen nicht nur Software; wir gestalten die digitale Zukunft neu und laden dich ein, dich uns anzuschließen.",
      whatsNextHeading: "ENTDECKE WAS ALS NÄCHSTES KOMMT",
      whatsNextSubHeading: "Heutige Innovationen, morgige Standards",
      whatsNextSubSubHeading:
        "EasyConsent ist eine intuitive und sichere Familien-App, die deine Daten und Privatsphäre respektiert und dafür sorgt, dass deine digitalen Vollmachten einfach zu erstellen und zu verwalten sind, egal wann und wo du bist.",
      contactSectionHeading: "KONTAKTIERE UNS",
      contactSectionSubHeading: "Lass uns über deine Ideen sprechen",
      ideasText: "Your ideas and investment can shape the future of technology. Whether you're an enthusiastic user, an investor looking to make an impact, or a potential partner, we're excited to hear from you.",
      namePlaceholder: "Dein Name",
      emailPlaceholder: "Deine Email",
      messagePlaceholder: "Deine Nachricht",
      submitButtonText: "SCHREIB UNS EINE NACHRICHT",
      privacyText:
        "Wir respektieren Ihre Privatsphäre. Indem Sie uns eine Nachricht senden, stimmen Sie ausdrücklich unseren Nutzungsbedingungen zu und erkennen unsere Datenschutzrichtlinie und Cookie-Richtlinie an. Ihre Informationen werden verwendet, um auf Ihre Anfrage zu antworten und wie in unserer Datenschutzrichtlinie beschrieben. Informationen dazu, wie Sie Ihre Zustimmung widerrufen oder Ihre Daten ändern können, finden Sie in unserer Datenschutzrichtlinie.",
      legalInformationHeadline: "RECHTLICHE INFORMATIONEN",
      legalInformationSubHeadline:
        "Bleibe informiert über deine Rechte und unsere Richtlinien",
      termsOfUseText: "Nutzungsbedingungen",
      privacyPolicyText: "Datenschutzrichtlinie",
      cookiePolicy: "Cookie-Richtlinie",
    },
  },
  privacy: {
    ar: web_ar,
    ca: web_ca,
    cs: web_cs,
    da: web_da,
    de: web_de,
    el: web_el,
    en: web_en,
    es: web_es,
    fi: web_fi,
    fr: web_fr,
    he: web_he,
    hi: web_hi,
    hr: web_hr,
    hu: web_hu,
    id: web_id,
    it: web_it,
    ja: web_ja,
    ko: web_ko,
    ms: web_ms,
    nl: web_nl,
    no: web_no,
    pl: web_pl,
    pt: web_pt,
    ro: web_ro,
    ru: web_ru,
    sk: web_sk,
    sv: web_sv,
    th: web_th,
    tr: web_tr,
    uk: web_uk,
    vi: web_vi
  },
  terms: {
    en: {
      align: "left",
      label: "English",
      updated: "Updated",
      date: "12/12/2023",
      welcome:
        "Welcome to the easyConsent website. By accessing and using our website, you agree to these Terms of Use.",
      intellectualTitle: "Intellectual Property Rights",
      content:
        "The content on our website, including text, graphics, logos, and images, is the property of easyConsent or used with permission. It is protected by intellectual property laws and may not be used, reproduced, or distributed without our prior written consent.",
      useTitle: "Use of the Website",
      useContent:
        "Our website is designed to provide information about our services and is intended for lawful purposes only. Any misuse or unauthorised use of the content or functionalities is strictly prohibited.",
      responsibilityTitle: "User Responsibilities",
      bullet1:
        "Contact Box: You must provide accurate and truthful information when using our contact box.",
      bullet2:
        "Prohibited Conduct: You may not use our website for any illegal, harmful, or abusive activities.",
      contentAccuracyTitle: "Content Accuracy",
      contentAccuracyContent:
        "While we strive to ensure that the information on our website is accurate, we cannot guarantee that all the information is always up-to-date or error-free.",
      linksTitle: "Links to Other Websites",
      linkesContent:
        "Our website may contain links to third-party websites. We are not responsible for the content or practices of these external sites.",
      modificationTitle: "Modification of Content",
      modificationContent:
        "We reserve the right to modify, update, or remove content from our website at our discretion.",
      warrantiesTitle: "Disclaimer of Warranties",
      warrantiesContent:
        'The website is provided "as is," and we make no warranties regarding its accuracy, completeness, or reliability. We disclaim all warranties to the fullest extent permitted by law.',
      limitationOfLiabilitiesTitle: "Limitation of Liability",
      limitationsContent:
        "easyConsent will not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of our website.",
      governingLawTitle: "Governing Law",
      governingContent:
        "These Terms of Use are governed by the laws of Spain, and any disputes arising from these Terms will be subject to the exclusive jurisdiction of the courts of Spain.",
      severabilityTitle: "Severability",
      severabilityContent:
        "If any part of these Terms is found to be unenforceable or invalid, the remainder will continue to be in effect.",
      waiverTitle: "Waiver",
      waiverContent:
        "Failure to enforce any part of these Terms does not constitute a waiver of our rights under these Terms.",
      contactUsTitle: "Contact Us",
      contactContent:
        "For any questions about these Terms of Use or for legal notices, please contact us at support@easyconsent.app.",
    },
    de: {
      align: "left",
      label: "Deutsch",
      updated: "Aktualisiert am",
      date: "12. Dezember 2023",
      welcome:
        "Willkommen auf der easyConsent-Website. Durch den Zugriff auf unsere Website und deren Nutzung stimmst du diesen Nutzungsbedingungen zu.",
      intellectualTitle: "Geistiges Eigentum",
      content:
        "Der Inhalt unserer Website, einschließlich Text, Grafiken, Logos und Bilder, ist Eigentum von easyConsent oder wird mit Genehmigung verwendet. Er ist durch geistige Eigentumsrechte geschützt und darf ohne unsere vorherige schriftliche Zustimmung nicht verwendet, reproduziert oder verteilt werden.",
      useTitle: "Nutzung der Website",
      useContent:
        "Unsere Website dient dazu, Informationen über unsere Dienstleistungen bereitzustellen und ist nur für rechtmäßige Zwecke vorgesehen. Jeglicher Missbrauch oder unbefugte Nutzung des Inhalts oder der Funktionen ist strengstens untersagt.",
      responsibilityTitle: "Nutzerpflichten",
      bullet1:
        "Kontaktformular: Du musst genaue und wahrheitsgemäße Informationen angeben, wenn du unser Kontaktformular verwendest.",
      bullet2:
        "Verbotenes Verhalten: Du darfst unsere Website nicht für illegale, schädliche oder missbräuchliche Aktivitäten nutzen.",
      contentAccuracyTitle: "Genauigkeit des Inhalts",
      contentAccuracyContent:
        "Obwohl wir sicherstellen, dass die Informationen auf unserer Website korrekt sind, können wir nicht garantieren, dass alle Informationen immer aktuell oder fehlerfrei sind.",
      linksTitle: "Links zu anderen Websites",
      linkesContent:
        "Unsere Website kann Links zu Websites Dritter enthalten. Wir sind nicht verantwortlich für den Inhalt oder die Praktiken dieser externen Websites.",
      modificationTitle: "Änderung des Inhalts",
      modificationContent:
        "Wir behalten uns das Recht vor, Inhalte auf unserer Website nach eigenem Ermessen zu ändern, zu aktualisieren oder zu entfernen.",
      warrantiesTitle: "Haftungsausschluss",
      warrantiesContent:
        'Die Website wird "wie besehen" zur Verfügung gestellt, und wir geben keine Garantien hinsichtlich ihrer Genauigkeit, Vollständigkeit oder Zuverlässigkeit ab. Wir lehnen alle Garantien im gesetzlich zulässigen Umfang ab.',
      limitationOfLiabilitiesTitle: "Haftungsbeschränkung",
      limitationsContent:
        "easyConsent haftet nicht für indirekte, zufällige, besondere, Folge- oder Strafschäden, die im Zusammenhang mit deiner Nutzung unserer Website stehen.",
      governingLawTitle: "Geltendes Rech",
      governingContent:
        "Diese Nutzungsbedingungen unterliegen den Gesetzen Spaniens, und alle Streitigkeiten aus diesen Bedingungen unterliegen der ausschließlichen Zuständigkeit der Gerichte Spaniens.",
      severabilityTitle: "Salvatorische Klausel",
      severabilityContent:
        "Sollte ein Teil dieser Bedingungen für nicht durchsetzbar oder ungültig befunden werden, so bleibt der Rest in Kraft.",
      waiverTitle: "Verzicht",
      waiverContent:
        "Die Nichtdurchsetzung eines Teils dieser Bedingungen stellt keinen Verzicht auf unsere Rechte aus diesen Bedingungen dar.",
      contactUsTitle: "Kontakt",
      contactContent:
        "Für Fragen zu diesen Nutzungsbedingungen oder für rechtliche Hinweise kontaktiere uns bitte unter support@easyconsent.app.",
    },
    es: {
      align: "left",
      label: "Español",
      updated: "Actualizado el",
      date: "12 de diciembre de 2023",
      welcome:
        "Bienvenido/a a la página web de easyConsent. Al acceder y utilizar nuestra página web, aceptas estas Condiciones de Uso.",
      intellectualTitle: "Derechos de Propiedad Intelectual",
      content:
        "El contenido de nuestra página web, incluyendo texto, gráficos, logotipos e imágenes, es propiedad de easyConsent o se utiliza con permiso. Está protegido por las leyes de propiedad intelectual y no puede ser utilizado, reproducido o distribuido sin nuestro consentimiento previo por escrito.",
      useTitle: "Uso de la Página Web",
      useContent:
        "Nuestra página web está diseñada para proporcionar información sobre nuestros servicios y está destinada únicamente para fines legítimos. Cualquier uso indebido o no autorizado del contenido o funcionalidades está estrictamente prohibido.",
      responsibilityTitle: "Responsabilidades del Usuario",
      bullet1:
        "Formulario de Contacto: Debes proporcionar información precisa y veraz al utilizar nuestro formulario de contacto.",
      bullet2:
        "Conducta Prohibida: No puedes utilizar nuestra página web para actividades ilegales, perjudiciales o abusivas.",
      contentAccuracyTitle: "Exactitud del Contenido",
      contentAccuracyContent:
        "Aunque nos esforzamos por garantizar que la información de nuestra página web sea precisa, no podemos garantizar que toda la información esté siempre actualizada o sea libre de errores.",
      linksTitle: "Enlaces a Otras Páginas Web",
      linkesContent:
        "Nuestra página web puede contener enlaces a sitios web de terceros. No somos responsables del contenido o prácticas de estos sitios web externos.",
      modificationTitle: "Modificación del Contenido",
      modificationContent:
        "Nos reservamos el derecho de modificar, actualizar o eliminar contenido de nuestra página web a nuestra discreción.",
      warrantiesTitle: "Descargo de Responsabilidad",
      warrantiesContent:
        'La página web se proporciona "tal como está", y no ofrecemos garantías sobre su exactitud, integridad o confiabilidad. Renunciamos a todas las garantías en la medida permitida por la ley.',
      limitationOfLiabilitiesTitle: "Limitación de Responsabilidad",
      limitationsContent:
        "easyConsent no será responsable de daños indirectos, incidentales, especiales, consecuentes o punitivos que surjan de o estén relacionados con tu uso de nuestra página web.",
      governingLawTitle: "Ley Aplicable",
      governingContent:
        "Estas Condiciones de Uso están sujetas a las leyes de España, y cualquier disputa derivada de estas condiciones estará sujeta a la jurisdicción exclusiva de los tribunales de España.",
      severabilityTitle: "Cláusula de Salvaguard",
      severabilityContent:
        "Si alguna parte de estas condiciones se considera inaplicable o inválida, el resto seguirá en vigor.",
      waiverTitle: "Renuncia",
      waiverContent:
        "La falta de aplicación de alguna parte de estas condiciones no constituye una renuncia a nuestros derechos en virtud de estas condiciones.",
      contactUsTitle: "Contáctanos",
      contactContent:
        "Si tienes preguntas sobre estas Condiciones de Uso o avisos legales, por favor contáctanos en support@easyconsent.app.",
    },
  },
  cookie: {
    en: {
      align: "left",
      label: "English",
      updated: "Updated",
      date: "December 12, 2023",
      welcome:
        'This Cookie Policy explains how easyConsent ("we", "us", "our") uses cookies and similar technologies to recognise you when you visit our website. It explains what these technologies are and why we use them, as well as your rights to control our use of them.',
      cookieTitle: "What are Cookies?",
      cookieContent:
        "Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners to make their websites work, as well as to provide reporting information.",
      whyUseCookieTitle: "Why Do We Use Cookies?",
      whyCookieContent:
        'We use cookies for several reasons. Some cookies are required for technical reasons in order for our website to operate, and we refer to these as "essential" cookies. Other cookies enable us to track and target the interests of our users to enhance the experience on our website. Third parties serve cookies through our website for analytics and other purposes.',
      typesTitle: "Types of Cookies Used on Our Website",
      typeBullet1:
        "Essential Cookies: These cookies are strictly necessary to provide you with services available through our website and to use some of its features, such as access to secure areas.",
      typeBullet2:
        "Analytics and Performance Cookies: These cookies are used to collect information about traffic to our site and how users use our site. The information gathered does not identify any individual visitor and is aggregated. It includes the number of visitors to our site, the websites that referred them to our site, the pages they visited on our site, what time of day they visited our site, whether they have visited our site before, and other similar information. We use this information to help operate our site more efficiently, to gather broad demographic information, and to monitor the level of activity on our site.",
      controlCookieTitle: "How Can You Control Cookies?",
      controlCookieContant:
        "You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with services.",
      changesCookieTitle: "Changes to the Cookie Policy",
      changesCookieContant:
        "We may update this Cookie Policy from time to time in order to reflect changes to the cookies we use or for other operational, legal, or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.",
      contactUsTitle: "Contact Us",
      contactUsContant:
        "If you have any questions about our use of cookies or other technologies, please email us at support@easyconsent.app.",
    },
    de: {
      align: "left",
      label: "Deutsch",
      updated: "Aktualisiert am",
      date: "12. Dezember 2023",
      welcome:
        'Diese Cookie-Richtlinie erläutert, wie easyConsent ("wir", "uns", "unser") Cookies und ähnliche Technologien verwendet, um dich zu erkennen, wenn du unsere Website besuchst. Sie erklärt, was diese Technologien sind, warum wir sie verwenden und deine Rechte zur Kontrolle ihrer Verwendung.',
      cookieTitle: "Was sind Cookies?",
      cookieContent:
        "Cookies sind kleine Dateien, die auf deinem Computer oder Mobilgerät platziert werden, wenn du eine Website besuchst. Cookies werden von Website-Betreibern weit verbreitet eingesetzt, um ihre Websites zu betreiben und Berichtsinformationen bereitzustellen.",
      whyUseCookieTitle: "Warum verwenden wir Cookies?",
      whyCookieContent:
        'Wir verwenden Cookies aus verschiedenen Gründen. Einige Cookies sind aus technischen Gründen erforderlich, damit unsere Website funktioniert, und wir bezeichnen sie als "essenzielle" Cookies. Andere Cookies ermöglichen es uns, die Interessen unserer Benutzer zu verfolgen und anzusprechen, um die Erfahrung auf unserer Website zu verbessern. Dritte setzen Cookies über unsere Website für Analyse- und andere Zwecke ein.',
      typesTitle: "Arten von Cookies auf unserer Website",
      typeBullet1:
        "Essenzielle Cookies: Diese Cookies sind streng erforderlich, um dir Dienste zur Verfügung zu stellen, die über unsere Website verfügbar sind, und um einige ihrer Funktionen zu nutzen, wie den Zugang zu sicheren Bereichen.",
      typeBullet2:
        "Analyse- und Leistungs-Cookies: Diese Cookies werden verwendet, um Informationen über den Verkehr auf unserer Website und die Verwendung unserer Website durch Benutzer zu sammeln. Die gesammelten Informationen identifizieren keinen einzelnen Besucher und werden aggregiert. Dazu gehören die Anzahl der Besucher unserer Website, die Websites, die sie auf unsere Website verwiesen haben, die von ihnen besuchten Seiten unserer Website, die Tageszeit, zu der sie unsere Website besucht haben, ob sie unsere Website zuvor besucht haben, und ähnliche Informationen. Wir verwenden diese Informationen, um unsere Website effizienter zu betreiben, um allgemeine demografische Informationen zu sammeln und um das Aktivitätsniveau auf unserer Website zu überwachen.",
      controlCookieTitle: "Wie kannst du Cookies kontrollieren?",
      controlCookieContant:
        "Du hast das Recht zu entscheiden, ob du Cookies akzeptieren oder ablehnen möchtest. Du kannst deine Cookie-Einstellungen im Cookie Consent Manager festlegen. Der Cookie Consent Manager ermöglicht es dir, auszuwählen, welche Kategorien von Cookies du akzeptierst oder ablehnst. Essenzielle Cookies können nicht abgelehnt werden, da sie streng erforderlich sind, um dir Dienste zur Verfügung zu stellen.",
      changesCookieTitle: "Änderungen der Cookie-Richtlinie",
      changesCookieContant:
        "Wir können diese Cookie-Richtlinie von Zeit zu Zeit aktualisieren, um Änderungen an den von uns verwendeten Cookies oder aus anderen betrieblichen, rechtlichen oder regulatorischen Gründen widerzuspiegeln. Bitte besuche diese Cookie-Richtlinie daher regelmäßig, um über unsere Verwendung von Cookies und verwandten Technologien informiert zu bleiben.",
      contactUsTitle: "Kontakt",
      contactUsContant:
        "Wenn du Fragen zur Verwendung von Cookies oder anderen Technologien hast, sende uns bitte eine E-Mail an support@easyconsent.app.",
    },
    es: {
      align: "left",
      label: "Español",
      updated: "Actualizado el",
      date: "12 de diciembre de 2023",
      welcome: "Esta Política de Cookies explica cómo easyConsent (\"nosotros\", \"nos\", \"nuestro\") utiliza cookies y tecnologías similares para reconocerte cuando visitas nuestro sitio web. Explica qué son estas tecnologías, por qué las utilizamos y tus derechos para controlar su uso.",
      cookieTitle: "¿Qué son las cookies?",
      cookieContent: "Las cookies son pequeños archivos que se colocan en tu ordenador o dispositivo móvil cuando visitas un sitio web. Los propietarios de sitios web utilizan ampliamente las cookies para que sus sitios funcionen y para proporcionar información de informes.",
      whyUseCookieTitle: "¿Por qué utilizamos cookies?",
      whyCookieContent: "Utilizamos cookies por diversas razones. Algunas cookies son necesarias por motivos técnicos para que nuestro sitio web funcione, y las llamamos cookies \"esenciales\". Otras cookies nos permiten rastrear y dirigir los intereses de nuestros usuarios para mejorar la experiencia en nuestro sitio web. Terceros utilizan cookies a través de nuestro sitio web para fines de análisis y otros propósitos.",
      typesTitle: "Tipos de cookies en nuestro sitio web",
      typeBullet1: "Cookies esenciales: Estas cookies son estrictamente necesarias para proporcionarte servicios disponibles a través de nuestro sitio web y para utilizar algunas de sus funciones, como el acceso a áreas seguras.",
      typeBullet2: "Cookies de análisis y rendimiento: Estas cookies se utilizan para recopilar información sobre el tráfico en nuestro sitio web y el uso de nuestro sitio web por parte de los usuarios. La información recopilada no identifica a un visitante individual y se agrega. Incluye el número de visitantes de nuestro sitio web, los sitios web que los remitieron a nuestro sitio web, las páginas que visitaron en nuestro sitio web, la hora del día en que visitaron nuestro sitio web, si han visitado nuestro sitio web anteriormente y otra información similar. Utilizamos esta información para operar nuestro sitio web de manera más eficiente, recopilar información demográfica general y supervisar el nivel de actividad en nuestro sitio web.",
      controlCookieTitle: "¿Cómo puedes controlar las cookies?",
      controlCookieContant: "Tienes el derecho de decidir si deseas aceptar o rechazar las cookies. Puedes ejercer tus derechos de cookies configurando tus preferencias en el Gestor de Consentimiento de Cookies. El Gestor de Consentimiento de Cookies te permite seleccionar qué categorías de cookies aceptas o rechazas. Las cookies esenciales no pueden ser rechazadas, ya que son estrictamente necesarias para proporcionarte servicios.",
      changesCookieTitle: "Cambios en la Política de Cookies",
      changesCookieContant: "Podemos actualizar esta Política de Cookies periódicamente para reflejar cambios en las cookies que utilizamos u otras razones operativas, legales o regulatorias. Por lo tanto, te recomendamos que visites esta Política de Cookies regularmente para mantenerte informado sobre nuestro uso de cookies y tecnologías relacionadas.",
      contactUsTitle: "Contacto",
      contactUsContant: "Si tienes preguntas sobre el uso de cookies u otras tecnologías, por favor envíanos un correo electrónico a support@easyconsent.app.",
    },
  },
  appPrivacy: {
    ar: { ...app_ar, label: "العربية" },
    ca: { ...app_ca, label: "Català" },
    cs: { ...app_cs, label: "Čeština" },
    da: { ...app_da, label: "Dansk" },
    de: { ...app_de, label: "Deutsch" },
    el: { ...app_el, label: "Ελληνικά" },
    en: { ...app_en, label: "English" },
    es: { ...app_es, label: "Español" },
    fi: { ...app_fi, label: "Suomi" },
    fr: { ...app_fr, label: "Français" },
    he: { ...app_he, label: "עברית" },
    hi: { ...app_hi, label: "हिन्दी" },
    hr: { ...app_hr, label: "Hrvatski" },
    hu: { ...app_hu, label: "Magyar" },
    id: { ...app_id, label: "Bahasa Indonesia" },
    it: { ...app_it, label: "Italiano" },
    ja: { ...app_ja, label: "日本語" },
    ko: { ...app_ko, label: "한국어" },
    ms: { ...app_ms, label: "Bahasa Melayu" },
    nl: { ...app_nl, label: "Nederlands" },
    no: { ...app_no, label: "Norsk" },
    pl: { ...app_pl, label: "Polski" },
    pt: { ...app_pt, label: "Português" },
    ro: { ...app_ro, label: "Română" },
    ru: { ...app_ru, label: "Русский" },
    sk: { ...app_sk, label: "Slovenčina" },
    sv: { ...app_sv, label: "Svenska" },
    th: { ...app_th, label: "ไทย" },
    tr: { ...app_tr, label: "Türkçe" },
    uk: { ...app_uk, label: "Українська" },
    vi: { ...app_vi, label: "Tiếng Việt" },
  }
};

export const align = {
  left: "flex-start",
  right: "flex-end",
  center: "center",
};

export const textAlign = {
  right: "end",
  left: "start",
  center: "center",
};