import React from "react";
import {
    StyledContentWrapper,
    StyledText,
    StyledHeading,
    StyledList,
    StyledUl
} from "../style";

const ContentPrivacy = ({ langObject }) => {
    return(
        <StyledContentWrapper align={langObject.align}>
            <StyledText align={langObject.align}>{langObject.updated}</StyledText>
            <StyledText align={langObject.align}>{langObject.date}</StyledText>
            <StyledText margin={"25px"} align={langObject.align}>{langObject.welcome}</StyledText>
            <StyledHeading align={langObject.align}>{langObject.personalInfoTitle}</StyledHeading>
            <StyledText align={langObject.align }>{langObject.personalInfoSub}</StyledText>
            <StyledUl direction={langObject.align} align={langObject.align}>
                <StyledList align={langObject.align}>{langObject.bullet1}</StyledList>
                <StyledList align={langObject.align}>{langObject.bullet2}</StyledList>
                <StyledList align={langObject.align}>{langObject.bullet3}</StyledList>
            </StyledUl>

            <StyledHeading align={langObject.align}>{langObject.collectInfoTitle}</StyledHeading>
            <StyledUl direction={langObject.align} align={langObject.align}>
                <StyledList align={langObject.align}>{langObject.collectedInfoBullet1}</StyledList>
                <StyledList align={langObject.align}>{langObject.collectedInfoBullet2}</StyledList>
            </StyledUl>

            <StyledHeading align={langObject.align}>{langObject.useCollectedTitle}</StyledHeading>
            <StyledUl direction={langObject.align} align={langObject.align}>
                <StyledList align={langObject.align}>{langObject.useBullet1}</StyledList>
                <StyledList align={langObject.align}>{langObject.useBullet2}</StyledList>
            </StyledUl>

            <StyledHeading align={langObject.align}>{langObject.dataSharingTitle}</StyledHeading>
            <StyledUl direction={langObject.align} align={langObject.align}>
                <StyledList align={langObject.align}>{langObject.dataSharingBullet1}</StyledList>
                <StyledList align={langObject.align}>{langObject.dataSharingBullet2}</StyledList>
            </StyledUl>

            <StyledHeading align={langObject.align}>{langObject.dataSecurityTitle}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.dataSecurityText}</StyledText>

            <StyledHeading align={langObject.align}>{langObject.userRightsTitle}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.userRightsText}</StyledText>

            <StyledHeading align={langObject.align}>{langObject.cookiesTitle}</StyledHeading>
            <StyledUl direction={langObject.align} align={langObject.align}>
                <StyledList align={langObject.align}>{langObject.cookiesBullet1}</StyledList>
                <StyledList align={langObject.align}>{langObject.cookiesBullet2}</StyledList>
            </StyledUl>

            <StyledHeading align={langObject.align}>{langObject.internationalTitle}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.internationalText}</StyledText>

            <StyledHeading align={langObject.align}>{langObject.changesTitle}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.changesText}</StyledText>

            <StyledHeading align={langObject.align}>{langObject.contactUsTitle}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.contactUsText}</StyledText>
        </StyledContentWrapper>
    );
}

export default ContentPrivacy;