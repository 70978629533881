import React from "react";
import {
    StyledContentWrapper,
    StyledText,
    StyledHeading,
    StyledList,
    StyledUl
} from "../style";

const ContentAppPrivacy = ({ langObject }) => {
    return(
        <StyledContentWrapper align={langObject.align}>
            <StyledText>{langObject.updated}</StyledText>
            <StyledText>{langObject.date}</StyledText>
            <StyledText margin={"25px"} align={langObject.align}>{langObject.privacyPolicy.introduction}</StyledText>
            <StyledText align={langObject.align}>{langObject.privacyPolicy.agreement}</StyledText>
            <StyledText align={langObject.align}>{langObject.personalInfoSub}</StyledText>

            <StyledHeading align={langObject.align}>{langObject.compliance.title}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.compliance.description}</StyledText>
            <StyledUl direction={langObject.align} align={langObject.align}>
                {langObject.compliance.laws.map((law) => (
                    <StyledList align={langObject.align}>{law}</StyledList>
                ))}
            </StyledUl>
            <StyledText align={langObject.align}>{langObject.compliance.location}</StyledText>

            <StyledHeading align={langObject.align} >{langObject.dataCollection.title}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.dataCollection.description}</StyledText>
            <StyledUl direction={langObject.align} align={langObject.align}>
                {langObject.dataCollection.categories.map((category) => (
                    <StyledList align={langObject.align}>{category}</StyledList>
                ))}
            </StyledUl>

            <StyledHeading>{langObject.legalBasis.title}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.legalBasis.description}</StyledText>
            <StyledUl direction={langObject.align} align={langObject.align}>
                {langObject.legalBasis.bases.map((base) => (
                    <StyledList align={langObject.align}>{base}</StyledList>
                ))}
            </StyledUl>
            <StyledText align={langObject.align}>{langObject.legalBasis.otherJurisdictions}</StyledText>

            <StyledHeading>{langObject.dataSharing.title}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.dataSharing.description}</StyledText>
            <StyledUl direction={langObject.align} align={langObject.align}>
                {langObject.dataSharing.categories.map((category) => (
                    <StyledList align={langObject.align}>{category}</StyledList>
                ))}
            </StyledUl>
            <StyledText align={langObject.align}>{langObject.dataSharing.localStorage}</StyledText>

            <StyledHeading>{langObject.childrenPrivacy.title}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.childrenPrivacy.description}</StyledText>

            <StyledHeading>{langObject.dataSecurity.title}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.dataSecurity.description}</StyledText>
            <StyledText align={langObject.align}>{langObject.dataSecurity.responsibility}</StyledText>

            <StyledHeading>{langObject.dataBreach.title}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.dataBreach.description}</StyledText>

            <StyledHeading>{langObject.legalCompliance.title}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.legalCompliance.description}</StyledText>
            <StyledUl direction={langObject.align} align={langObject.align}>
                {langObject.legalCompliance.circumstances.map((circumstance) => (
                    <StyledList align={langObject.align}>{circumstance}</StyledList>
                ))}
            </StyledUl>

            <StyledHeading>{langObject.userRights.title}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.userRights.description}</StyledText>
            <StyledUl direction={langObject.align} align={langObject.align}>
                {langObject.userRights.rights.map((right) => (
                    <StyledList align={langObject.align}>{right}</StyledList>
                ))}
            </StyledUl>
            <StyledText align={langObject.align}>{langObject.userRights.contact}</StyledText>

            <StyledHeading>{langObject.dataRetention.title}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.dataRetention.description}</StyledText>

            <StyledHeading>{langObject.thirdPartyServices.title}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.thirdPartyServices.description}</StyledText>

            <StyledHeading>{langObject.internationalTransfers.title}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.internationalTransfers.description}</StyledText>

            <StyledHeading>{langObject.policyChanges.title}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.policyChanges.description}</StyledText>

            <StyledHeading>{langObject.contact.title}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.contact.description}</StyledText>

            <StyledHeading>{langObject.finalAcknowledgment.title}</StyledHeading>
            <StyledText align={langObject.align}>{langObject.finalAcknowledgment.description}</StyledText>
        </StyledContentWrapper>
    );
}

export default ContentAppPrivacy;